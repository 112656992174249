
  

  .rbc-time-header { background-color: #F6F7FB; border:none; border-radius: 20px 20px 0px 0px; }

  .rbc-row { background-color: #F6F7FB; border:none; border-radius: 20px; }

  .rbc-header { 
      border: none; 
      border-bottom: none !important;
      padding: 20px 3px !important;
      text-transform: uppercase;
      border-radius: 20px;
  }

  /* .rbc-header:nth-child(3){
      border-radius: 20px !important;
  } */

  .rbc-button-link{
      text-transform: uppercase;
  }

  .rbc-time-view {border:none !important}

  .rbc-time-header-content {
    border-left: none !important;
    border-radius: 20px;
    
  }

  

  .rbc-header + .rbc-header{
      border-left: none !important;
  }

  .rbc-time-view .rbc-row{
      min-height: auto !important;
  }

  .rbc-today{
      background-color: transparent !important;
  }

  .rbc-current-time-indicator{
      background-color: transparent !important;
  }

  .rbc-time-header.rbc-overflowing {
      border-right: none !important;
  }

  .rbc-time-content > * + * > *{
      border-left: 1px solid #D4D9EA !important;
  }

  .rbc-timeslot-group{
      border-bottom: 1px dashed #D4D9EA !important ;
  }
